var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "관련 사진" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo1,
                    label: "LBL00001551",
                    editable: false,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo2,
                    label: "LBL00001552",
                    editable: false,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo3,
                    label: "LBL0002340",
                    editable: false,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }