<template>
    <div>
      <c-card title="관련 사진" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <!-- 원거리 -->
            <c-upload 
              :attachInfo="attachInfo1"
              label="LBL00001551"
              :editable="false">
            </c-upload>
          </div>
          <div class="col-12">
            <!-- 근거리 -->
            <c-upload 
              :attachInfo="attachInfo2"
              label="LBL00001552"
              :editable="false">
            </c-upload>
          </div>
          <div class="col-12">
            <!-- 피해정도 관련 사진 -->
            <c-upload 
              :attachInfo="attachInfo3"
              label="LBL0002340"
              :editable="false">
            </c-upload>
          </div>
        </template>
      </c-card>
    </div>
  </template>
  <script>
  export default {
    name: 'accident-picture-TL',
    props: {
      param: {
        type: Object,
        default: () => ({
          iimAccidentId: '',
          stepCd: '',
        }),
      },
    },
    data() {
      return {
        editable: true,
        getUrl: '',
        attachInfo1: {
          isSubmit: '',
          taskClassCd: 'PROCESS_FIRST_PICTURE',
          taskKey: '',
        },
        attachInfo2: {
          isSubmit: '',
          taskClassCd: 'PROCESS_SECOND_PICTURE',
          taskKey: '',
        },
        attachInfo3: {
          isSubmit: '',
          taskClassCd: 'PROCESS_DEGREE_DAMAGE',
          taskKey: '',
        },
      };
    },
    computed: {
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
      Object.assign(this.$data, this.$options.data());
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {
    },
    methods: {
      init() {
        // role setting
        // url setting
        this.$set(this.attachInfo1, 'taskKey', this.param.iimAccidentId)
        this.$set(this.attachInfo2, 'taskKey', this.param.iimAccidentId)
        this.$set(this.attachInfo3, 'taskKey', this.param.iimAccidentId)
        // code setting
        // list setting
      },
    }
  };
  </script>
  